import { Fragment, useEffect, useState } from 'react'
import { IUserItem, UserRoles } from '../../infrastructure/interfaces/users'
import { Outlet, useLocation } from 'react-router-dom'
import { Container } from '../../components/commons/Layouts/styles'
import { connect, useDispatch } from 'react-redux'
import Layout from '../../components/commons/Layouts'
import { settingsService } from '../../infrastructure/services/settingsService'
import { setEntityList } from '../../infrastructure/store/multitenant/multitenantSlice'
import { ICompanyItem } from '../../infrastructure/interfaces/settings'
import { authService } from '../../infrastructure/services/authService'
import ChildrenModal from '../../components/commons/Sidebar/ChildrenModal/ChildrenModal'
import { useSelector } from 'react-redux'
import { RootState } from '../../infrastructure/store'
import { checkPermissions } from '../../infrastructure/utils/permissions'
import Sidebar from '../../components/commons/Sidebar/Sidebar'

interface IProps {
  auth: any
  entities: ICompanyItem[]
  selectedEntity: ICompanyItem
}

const FedeBi = ({ auth, entities, selectedEntity }: IProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    if (auth) {
      if (auth.isAuthenticated && auth.user) {
        if (auth.user?.role.name === UserRoles.ADMIN) {
          if (entities?.length === 0) {
            getEntities()
          }
        }
      }
    }
  }, [auth])

  useEffect(() => {
    let calling = false
    if (!calling) {
      authService.getProfile().then((res) => {
        setIsLoading(false)
        localStorage.setItem('user', JSON.stringify(res.data))
      })
      calling = true
    }
  }, [])

  const getEntities = async () => {
    await settingsService
      .getCompanies({
        per_page: 500,
      })
      .then((res) => {
        dispatch(setEntityList(res.data.items))
      })
  }

  return (
    <Layout>
      <Fragment>
        {checkPermissions(
          ['menus.*', 'menus.list', 'menus.view'],
          auth?.user?.role?.permissions
        ) || auth?.user?.role?.name === UserRoles.ADMIN ? (
          <Sidebar
            auth={auth}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        ) : null}

        <div className="tw-flex tw-w-full tw-flex-col tw-relative">
          <div className="tw-absolute tw-h-full tw-w-full tw-overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </Fragment>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    entities: state.multitenant?.entityList,
    selectedEntity: state.multitenant?.entity,
  }
}

export default connect(mapStateToProps)(FedeBi)
